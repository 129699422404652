import type { AxiosError } from "axios";
import axios from "axios";
import moment from "moment";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import styled from "styled-components/macro";
import { AccordionCardWithEditFeatures } from "../../../../components/AccordionCard/AccordionCardwithEditFeatures";
import type { ProductGroupFormValues } from "../../../../components/AttributeGroupFormGenerator/AttributeGroupFormGenerator";
import { AttributeGroupFormGenerator } from "../../../../components/AttributeGroupFormGenerator/AttributeGroupFormGenerator";
import { useAuthContext } from "../../../../components/Auth";
import type { ChipType } from "../../../../components/Chips/Chips";
import { Notifications } from "../../../../components/Notifications/NotificationsContext";
import { TooltipPortal } from "../../../../components/TooltipModal/TooltipPortal";
import { endpoints } from "../../../../endpoints";
import { TwoColumGrid } from "../../../../layout/portalPageLayout";
import theme from "../../../../theme";
import type { DataMutate, OptionType, UUID } from "../../../../types/types";
import type {
  AttributeGroupSummary,
  AttributeSchema,
  ImageAttributeValue,
  PIMProduct,
} from "../../../../types/types.PIM";
import { useStoreState, useUpdateProductStatus } from "../../../../util/util";
import { normalizeAttributeName } from "../../../admin/SellerAdmin/PIM/components/PIM.components.util";
import { AttributeValueDisplay } from "./product_overview.utils";

interface IProductGroupCardProps {
  product: PIMProduct;
  group: AttributeGroupSummary;
  mutateProduct: DataMutate<PIMProduct>;
  replaceProductId: (id: string) => void;
  key: string;
  isPortfolio?: boolean;
}
interface IProductGroupCardDetailsProps {
  group: AttributeGroupSummary;
  isPortfolio?: boolean;
  showMandatoryAsterisk: boolean;
}

const EditFormWrapper = styled.div`
  margin-top: 20px;
`;

const InnerTitleText = styled.p`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  display: inline-block;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  margin: 10px 0 5px;
`;

const ProductGroupCardDetails = ({
  group,
  isPortfolio = false,
  showMandatoryAsterisk,
}: IProductGroupCardDetailsProps) => {
  const { t } = useTranslation();

  const { roleIsSomeKindOfSeller } = useAuthContext();
  const entirelySelectAttributes = group.attributes.every(
    (attr) =>
      (attr.input_type === "single_select" ||
        attr.input_type === "multi_select") &&
      attr.icons_enabled
  );

  const entirelyImageAttributes = group.attributes.every(
    (attr) => attr.input_type === "image"
  );

  const content = group.attributes.reduce<JSX.Element[]>((acc, attribute) => {
    if (
      isPortfolio &&
      (!attribute.values?.[0]?.value || attribute.is_restricted)
    ) {
      return acc;
    }

    const attributeContent = (
      <div key={attribute.id}>
        <InnerTitleText
          data-for={attribute.id}
          data-tip={
            roleIsSomeKindOfSeller && attribute.description
              ? attribute.description
              : undefined
          }
        >
          {attribute.display_name
            ? t([attribute.display_name]) +
              (attribute.is_mandatory && !isPortfolio && showMandatoryAsterisk
                ? " *"
                : "")
            : attribute.name +
              (attribute.is_mandatory && !isPortfolio && showMandatoryAsterisk
                ? " *"
                : "")}
        </InnerTitleText>
        <AttributeValueDisplay
          attribute={attribute}
          isPortfolio={isPortfolio}
        />
        {roleIsSomeKindOfSeller && attribute.description?.replace(/\s/g, "") ? (
          <TooltipPortal>
            <ReactTooltip
              id={attribute.id}
              place="top"
              data-html={true}
              effect="solid"
              backgroundColor={theme.tooltipBG}
              multiline={true}
            />
          </TooltipPortal>
        ) : (
          <></>
        )}
      </div>
    );

    acc.push(attributeContent);
    return acc;
  }, []);

  return entirelySelectAttributes || entirelyImageAttributes ? (
    <div>{content}</div>
  ) : (
    <TwoColumGrid>{content}</TwoColumGrid>
  );
};

export const ProductGroupCard = ({
  product,
  group,
  mutateProduct,
  isPortfolio = false,
  replaceProductId,
}: IProductGroupCardProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const { tenant_id } = useStoreState();
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { t } = useTranslation();
  const updateProductStatus = useUpdateProductStatus({ product });
  const { hasPermission } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const methodsOfUseForm = useForm({
    defaultValues: {
      ...group.attributes.reduce((acc, attr) => {
        acc[`${normalizeAttributeName(attr.name)}__notApplicable`] =
          attr.is_not_applicable === true;
        return acc;
      }, {} as Record<string, any>),
    },
  });

  const getGroupPatchData = (formValues: ProductGroupFormValues) => {
    // Get all keys including __notApplicable fields
    const allKeys = Object.keys(formValues);

    // Filter out empty values but keep fields that are marked as not applicable
    const keys = allKeys.filter(
      (key) =>
        !key.endsWith("__notApplicable") && // Exclude the flag fields themselves
        formValues[key] !== undefined &&
        formValues[key] !== "" &&
        formValues[key] !== " " &&
        formValues[key] !== null &&
        key !== "display_text" &&
        key !== ""
    );

    let valuesArray: {
      attribute_id: string | undefined;
      value?:
        | string
        | number
        | boolean
        | ChipType
        | { display_text: string; url: string }
        | Record<string, string>;
      is_not_applicable?: boolean;
    }[] = [];

    keys.forEach((key) => {
      if (Array.isArray(formValues[key])) {
        (
          formValues[key] as Array<string | boolean | number | ChipType>
        ).forEach((value: string | boolean | number | ChipType) => {
          valuesArray.push({
            attribute_id: group.attributes.find(
              (attr) =>
                normalizeAttributeName(attr.name) ===
                normalizeAttributeName(key)
            )?.id,
            value: (value as ChipType).id ?? value,
          });
        });
      } else {
        const attributeValue = () => {
          const attribute = group.attributes.find(
            (attr) =>
              normalizeAttributeName(attr.name) === normalizeAttributeName(key)
          ) as AttributeSchema;

          if (attribute?.input_type === "date") {
            return moment(formValues[key] as string).format("MMM DD, YYYY");
          } else if (
            attribute?.input_type === "single_select" ||
            (attribute?.input_type === "product_reference" &&
              !attribute.is_multiple_choice)
          ) {
            return (formValues[key] as unknown as OptionType).value as string;
          } else if (attribute?.input_type === "link") {
            const linkValue = formValues[key] as {
              display_text: string;
              url: string;
            };
            // Return null if both display_text and url are empty strings
            if (linkValue.display_text === "" && linkValue.url === "") {
              return null;
            }
            return linkValue;
          } else if (attribute?.input_type === "image") {
            return formValues[key] as ImageAttributeValue;
          } else if (attribute?.input_type === "toggle") {
            return JSON.stringify(formValues[key]);
          }
          return formValues[key] as string | boolean | number;
        };

        const value = attributeValue();
        if (value) {
          valuesArray.push({
            attribute_id: group.attributes.find(
              (attr) =>
                normalizeAttributeName(attr.name) ===
                normalizeAttributeName(key)
            )?.id,
            value,
          });
        }
      }
    });

    const attributesToMakeApplicable: UUID[] = [];
    const attributesToMakeUnapplicable: UUID[] = [];

    group.attributes.forEach((attribute) => {
      const notApplicableKey = `${normalizeAttributeName(
        attribute.name
      )}__notApplicable`;
      // Check form value first, then fall back to the attribute's original state
      const isNotApplicable =
        formValues[notApplicableKey] === true ||
        (formValues[notApplicableKey] === undefined &&
          attribute.is_not_applicable === true);

      if (isNotApplicable) {
        attributesToMakeUnapplicable.push(attribute.id);
      } else {
        attributesToMakeApplicable.push(attribute.id);
      }
    });

    return {
      values: valuesArray,
      applicability: {
        attributes_to_make_applicable: attributesToMakeApplicable,
        attributes_to_make_unapplicable: attributesToMakeUnapplicable,
      },
    };
  };
  const onSubmit = async (formValues: ProductGroupFormValues) => {
    try {
      setIsLoading(true);
      const { data: updatedProduct } = await updateProductStatus();
      const { values, applicability } = getGroupPatchData(formValues);

      await axios.put(
        `/v2/tenants/${tenant_id}/pim/products/${updatedProduct.product_number}/groups/${group.id}/applicability`,
        applicability
      );

      const { data } = await axios.put<PIMProduct>(
        `/v2/tenants/${tenant_id}/pim/products/${updatedProduct.id}/groups/${group.id}`,
        { values }
      );

      if (
        data.display_status === "staged" ||
        data.display_status === "unpublished_staged"
      ) {
        const product_id =
          data.product_number ?? data.primary_staged_product_id ?? data.id;
        replaceProductId(product_id);
      } else {
        await mutateProduct();
      }
      notifySuccess(t("Your changes have been saved"));
      setIsEditMode(false);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error saving your changes"),
        {
          error,
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const onCreateOption = async (value: string, attribute: AttributeSchema) => {
    if (!hasPermission("modify_lists")) {
      notifyError(
        t("You don't have permission to modify list {{list_name}}", {
          list_name: attribute.list_name,
        })
      );
      return;
    }
    try {
      const response = await axios.post<{ name: string; id: UUID }>(
        endpoints.v2_tenants_id_or_slug_pim_lists_id_items(
          tenant_id,
          attribute.list_id
        ),
        {
          name: value,
        }
      );
      if (attribute.input_type === "single_select") {
        methodsOfUseForm.setValue(normalizeAttributeName(attribute.name), {
          value: response.data.id,
          label: value,
        });
        mutateProduct();
      }
      notifySuccess(
        t("{{value}} was saved to the {{list_name}} list", {
          value: value,
          list_name: attribute.list_name,
        })
      );
    } catch (error) {
      notifyError(
        t("There was an error adding {{value}} to the {{list_name}} list", {
          value: value,
          list_name: attribute.list_name,
        }),
        { error }
      );
    }
  };

  const onCreateImageAttr = async (
    image_attr: ImageAttributeValue | null,
    attribute: AttributeSchema
  ) => {
    methodsOfUseForm.setValue(
      normalizeAttributeName(attribute.name),
      image_attr
    );
  };

  return (
    <AccordionCardWithEditFeatures
      title={group.display_name ? t([group.display_name]) : t(group.name)}
      handleEdit={
        !isPortfolio &&
        hasPermission("modify_products") &&
        product.is_editable &&
        product.status !== "archived"
          ? () => setIsEditMode(!isEditMode)
          : undefined
      }
      expanded={true}
      editing={isEditMode}
    >
      {!isEditMode && (
        <ProductGroupCardDetails
          group={group}
          isPortfolio={isPortfolio}
          showMandatoryAsterisk={
            product.product_schema.show_mandatory_completion_score &&
            !isPortfolio
          }
        />
      )}
      {!isPortfolio && isEditMode && product.status !== "archived" && (
        <EditFormWrapper>
          <AttributeGroupFormGenerator
            attributes={group.attributes as AttributeSchema[]}
            onSubmit={onSubmit}
            onCancel={() => setIsEditMode(false)}
            methodsOfUseForm={methodsOfUseForm}
            onCreateOption={onCreateOption}
            onCreateImageAttribute={onCreateImageAttr}
            isLoading={isLoading}
            showMandatoryAsterisk={
              product.product_schema.show_mandatory_completion_score
            }
          />
        </EditFormWrapper>
      )}
    </AccordionCardWithEditFeatures>
  );
};
