import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { InvisibleButton } from "../../../../../../components/Buttons/Buttons";
import { DropDown } from "../../../../../../components/DropDown/DropDown";
import {
  ActionsIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  InfoIcon,
  LinkIcon,
  LockIcon,
  NonVisibleIcon,
  UnLockIcon,
  VisibleIcon,
} from "../../../../../../components/Icons/Icons";
import {
  RegularTextSmall,
  SmallText,
  SoftHeader2,
} from "../../../../../../components/Typography/Typography";
import type { Assets, GridItemProps } from "../../../../../../types/types.PIM";
import {
  RenderOnViewportEntry,
  useStoreState,
} from "../../../../../../util/util";
import {
  GetDocumentIcon,
  TrimmedName,
  get_content_type,
} from "../util/AssetsUtil";
import { AssetDocViewer } from "./AssetDocViewer";
import { CheckBoxNoLabel } from "../../../../../../components/CheckBoxes/CheckBoxes";
import { Fragment, useContext, useEffect, useState } from "react";
import { DocumentPreview } from "./DocumentPreview";
import ReactTooltip from "react-tooltip";
import { RadioButton } from "../../../../../../components/RadioButton/RadioButton";
import type { Row } from "@tanstack/react-table";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";

const GridContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-left: 4px solid ${({ theme }) => theme.secondaryBorder};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 24px;
  gap: 24px;
`;

const GridItemContainer = styled.div`
  position: relative;
  border: 1px solid ${({ theme }) => theme.secondaryBorder};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  cursor: pointer;
  height: 300px;
  > div:first-child {
    display: flex;
    align-items: center;
    flex: 0.2;
    gap: 8px;
  }
  .grid_item_dropdown {
    z-index: 2;
    > button {
      padding: 0;
    }
  }
`;

const DocViewerContainer = styled.div<{ can_view: boolean }>`
  position: relative;
  background-color: #eee; // match the pdf container background
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: ${({ can_view }) => (can_view ? "pointer" : "not-allowed")};
  .lock_icon {
    display: ${({ can_view }) => (can_view ? "none" : "flex")};
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 45%;
    left: 45%;
  }
`;

const GridDocumentIconContainer = styled.div`
  flex: 0.2;
`;

const AssetNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
`;

const DropDownItemContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
`;

const CheckBoxContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
  gap: 8px;
`;

export type GridAssetsSelectProps = {
  selectedItems?: Row<Assets>[];
  handleSelectedItems?: (rows: Row<Assets>[]) => void;
};

type GridItemSelectProps = {
  can_select?: boolean;
  is_selected?: boolean;
  on_select_item?: (asset: Assets) => void;
};

const GridItem = ({
  asset,
  handle_asset_visibility_change,
  on_edit_asset,
  on_download_asset,
  on_remove_asset,
  is_selected,
  can_select,
  on_select_item,
  assets,
  is_existing_asset_page = false,
  select_type,
  show_download_checkbox = false,
  productSlug,
  isShowCheckbox = false,
  isDisabledCheckbox = false,
  bulkSelectLimit,
  selectedRowCount,
}: {
  asset: Assets & GridItemProps;
  assets: (Assets & GridItemProps)[];
  handle_asset_visibility_change?: (asset: Assets) => void;
  on_edit_asset?: (asset: Assets) => void;
  on_download_asset?: (asset: Assets) => void;
  on_remove_asset?: (asset: Assets) => void;
  is_existing_asset_page?: boolean;
  select_type: "radio" | "checkbox";
  show_download_checkbox?: boolean;
  productSlug?: string;
  isShowCheckbox?: boolean;
  isDisabledCheckbox?: boolean;
  bulkSelectLimit: number;
  selectedRowCount: number;
} & GridItemSelectProps) => {
  const [show_preview, set_show_preview] = useState(false);
  const { notifySuccess, notifyError } = useContext(Notifications);
  const { is_publishing_product, slug } = useStoreState();
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    can_edit,
    can_change_visibility,
    can_delete,
    can_external_download = true,
    asset_type,
    name,
    signed_url,
  } = asset;
  const actionItems = [
    ...[
      {
        name: "copyLink",
        item: (
          <DropDownItemContainer
            onClick={() =>
              navigator.clipboard
                .writeText(
                  productSlug
                    ? `https://${window.location.hostname}/api/store/${slug}/products/${productSlug}/${asset_type}/${name}`
                    : `https://${window.location.hostname}${signed_url}`
                )
                .then(
                  function () {
                    notifySuccess(t("Asset link copied to clipboard"));
                  },
                  function (err) {
                    notifyError("something went wrong.", err);
                  }
                )
            }
          >
            <LinkIcon width={20} height={20} />
            <span id="copyLink-dropdown-text">{t("Copy to clipboard")}</span>
          </DropDownItemContainer>
        ),
      },
    ],
    ...(can_edit && !!on_edit_asset
      ? [
          {
            name: "edit",
            item: (
              <DropDownItemContainer
                onClick={() => {
                  on_edit_asset(asset);
                }}
              >
                <EditIcon width={20} height={20} />
                <span id="edit-dropdown-text">{t("Edit")}</span>
              </DropDownItemContainer>
            ),
          },
        ]
      : []),
    ...(can_external_download && !!on_download_asset
      ? [
          {
            name: "download",
            item: (
              <DropDownItemContainer
                onClick={() => {
                  on_download_asset(asset);
                }}
              >
                <DownloadIcon width={20} height={20} />
                <span id="download-dropdown-text">{t("Download")}</span>
              </DropDownItemContainer>
            ),
          },
        ]
      : []),
    ...(can_delete && !!on_remove_asset
      ? [
          {
            name: "delete",
            item: (
              <DropDownItemContainer
                onClick={() => {
                  on_remove_asset(asset);
                }}
              >
                <DeleteIcon width={20} height={20} fill={theme.errorColor} />
                <span id="delete-dropdown-text">{t("Remove")}</span>
              </DropDownItemContainer>
            ),
          },
        ]
      : []),
  ];

  const get_action_item = (name: string, is_existing_asset_page: boolean) => {
    if (name === "edit") {
      return can_edit && !!on_edit_asset ? (
        <InvisibleButton
          style={{ flex: 0.2, justifySelf: "flex-end", padding: 0 }}
          onClick={() => {
            on_edit_asset(asset);
          }}
        >
          <EditIcon width={20} height={20} />
        </InvisibleButton>
      ) : (
        <></>
      );
    } else if (name === "download") {
      return can_external_download &&
        !!on_download_asset &&
        !is_existing_asset_page ? (
        <InvisibleButton
          style={{ flex: 0.2, justifySelf: "flex-end", padding: 0 }}
          onClick={() => {
            on_download_asset(asset);
          }}
        >
          <DownloadIcon width={20} height={20} />
        </InvisibleButton>
      ) : (
        <></>
      );
    } else {
      return can_delete && !!on_remove_asset ? (
        <InvisibleButton
          style={{ flex: 0.2, justifySelf: "flex-end", padding: 0 }}
          onClick={() => {
            on_remove_asset(asset);
          }}
        >
          <DeleteIcon width={20} height={20} fill={theme.errorColor} />
        </InvisibleButton>
      ) : (
        <></>
      );
    }
  };

  const get_tooltip_message = (asset_type: Assets["asset_type"]) => {
    return asset_type === "document"
      ? t("Register to access this document")
      : asset_type === "image"
      ? t("Register to access this image")
      : t("Register to access this video");
  };

  const handle_select_item = () => {
    if (on_select_item) {
      on_select_item(asset);
    }
  };

  const on_preview_click = () => {
    if (can_external_download) {
      set_show_preview(true);
    }
  };

  const assets_to_preview = assets.filter((a) => a.can_external_download);
  const hasReachedBulkSelectLimit: boolean =
    selectedRowCount >= bulkSelectLimit;

  return (
    <GridItemContainer
      data-for={`locked_asset${asset.id}`}
      data-tip={
        !can_external_download ? get_tooltip_message(asset.asset_type) : ""
      }
    >
      <div>
        {show_download_checkbox && (
          <div>
            <CheckBoxNoLabel
              checked={is_selected}
              onChange={() => handle_select_item()}
              style={{ marginRight: "0" }}
              disabled={
                !is_selected &&
                (!can_external_download || hasReachedBulkSelectLimit)
              }
            />
          </div>
        )}
        <GridDocumentIconContainer onClick={on_preview_click}>
          <GetDocumentIcon
            asset_type={asset.asset_type}
            file_type={get_content_type(asset.content_type ?? "pdf")}
          />
        </GridDocumentIconContainer>
        <AssetNameContainer onClick={on_preview_click}>
          <TrimmedName text={asset.name} Content={SoftHeader2} />
          <RegularTextSmall>{asset.asset_category}</RegularTextSmall>
        </AssetNameContainer>
        {can_change_visibility && !!handle_asset_visibility_change && (
          <>
            {asset.asset_type === "document" ? (
              <InvisibleButton
                disabled={is_publishing_product}
                onClick={() => {
                  handle_asset_visibility_change(asset);
                }}
                data-for={`document-tooltip-${asset.id}`}
                data-tip={
                  asset.visibility === "public"
                    ? t("Public")
                    : asset.visibility === "logged_in_user"
                    ? t("Customers & Internal Users")
                    : t("Internal Users Only")
                }
              >
                {asset.visibility === "public" ? (
                  <UnLockIcon
                    fill={
                      is_publishing_product ? theme.disabledButtonBG : undefined
                    }
                    width={20}
                    height={20}
                  />
                ) : asset.visibility === "logged_in_user" ? (
                  <UnLockIcon
                    fill={
                      is_publishing_product ? theme.disabledButtonBG : "#f69900"
                    }
                    width={20}
                    height={20}
                  />
                ) : (
                  <LockIcon
                    fill={
                      is_publishing_product ? theme.disabledButtonBG : undefined
                    }
                    width={20}
                    height={20}
                  />
                )}
                <ReactTooltip id={`document-tooltip-${asset.id}`} />
              </InvisibleButton>
            ) : (
              <InvisibleButton
                disabled={
                  is_publishing_product ||
                  (asset.visibility === "public" && asset.is_cover_image)
                }
                onClick={() => {
                  handle_asset_visibility_change(asset);
                }}
                data-for={`media-tooltip-${asset.id}`}
                data-tip={
                  asset.visibility === "public" && asset.is_cover_image
                    ? t("You can't hide the product cover image")
                    : asset.visibility === "public" && !asset.is_cover_image
                    ? t("Public")
                    : asset.visibility === "logged_in_user"
                    ? t("Customers & Internal Users")
                    : t("Internal Users Only")
                }
              >
                {asset.visibility === "public" ? (
                  <VisibleIcon
                    width={20}
                    height={20}
                    fill={
                      asset.is_cover_image
                        ? theme.disabledLinkColor
                        : theme.successIconColor
                    }
                  />
                ) : asset.visibility === "logged_in_user" ? (
                  <VisibleIcon
                    width={20}
                    height={20}
                    fill={
                      asset.is_cover_image ? theme.disabledLinkColor : "#f69900"
                    }
                  />
                ) : (
                  <NonVisibleIcon
                    width={20}
                    height={20}
                    fill={theme.errorColor}
                  />
                )}
                <ReactTooltip id={`media-tooltip-${asset.id}`} />
              </InvisibleButton>
            )}
          </>
        )}
        {actionItems.length > 1 ? (
          <InvisibleButton
            style={{ flex: 0.2, justifySelf: "flex-end", padding: 0 }}
          >
            <DropDown
              placeholder={<ActionsIcon />}
              showIcon={false}
              activeItem={""}
              items={[
                ...actionItems.map(({ item, name }) => (
                  <Fragment key={name}>{item}</Fragment>
                )),
              ]}
              direction="right"
              clickHandler={() => {}}
              className="grid_item_dropdown"
            />
          </InvisibleButton>
        ) : actionItems.length === 1 ? (
          get_action_item(actionItems[0].name, is_existing_asset_page)
        ) : (
          <></>
        )}
      </div>
      <DocViewerContainer
        onClick={on_preview_click}
        can_view={can_external_download}
      >
        {can_external_download && (
          <AssetDocViewer mode="thumbnail" asset={asset} />
        )}
        <div className="lock_icon">
          <LockIcon fill={theme.primaryIconColor} />
        </div>
      </DocViewerContainer>
      {can_select && !show_download_checkbox && (
        <CheckBoxContainer>
          {asset.visibility !== "public" && (
            <div
              style={{ position: "relative", top: "2px" }}
              data-tip={t("This asset is not accessible outside login")}
              data-for="is_downloadable-tooltip"
            >
              <InfoIcon
                width={16}
                height={16}
                fill={theme.secondaryIconColor}
              />
              <ReactTooltip effect="solid" id="is_downloadable-tooltip" />
            </div>
          )}
          {select_type === "checkbox" ? (
            <CheckBoxNoLabel
              checked={is_selected}
              onChange={() => handle_select_item()}
            />
          ) : (
            <RadioButton
              handleChange={handle_select_item}
              checked={is_selected}
              name={asset.name}
              value={asset.id}
            />
          )}
        </CheckBoxContainer>
      )}
      {can_external_download && show_preview && (
        <DocumentPreview
          assets={assets_to_preview}
          selected_asset_idx={assets_to_preview.findIndex(
            (a) => a.id === asset.id
          )}
          onCancelPreview={() => {
            set_show_preview(false);
          }}
          onEdit={can_edit ? on_edit_asset : undefined}
          onDownload={can_external_download ? on_download_asset : undefined}
        />
      )}
      <ReactTooltip effect="solid" id={`locked_asset${asset.id}`} />
    </GridItemContainer>
  );
};

export const GridAssetView = ({
  assets,
  handle_asset_visibility_change,
  on_edit_asset,
  on_download_asset,
  on_remove_asset,
  selectedItems,
  handleSelectedItems,
  is_existing_asset_page = false,
  select_type = "checkbox",
  show_download_checkbox = false,
  productSlug,
  isShowCheckbox = false,
  isDisabledCheckbox = false,
  isAllCheckBoxUnselected,
  is_page_selected = false,
  bulkSelectLimit,
}: {
  assets: (Assets & GridItemProps)[];
  handle_asset_visibility_change?: (asset: Assets) => void;
  on_remove_asset?: (asset: Assets) => void;
  on_edit_asset?: (asset: Assets) => void;
  on_download_asset?: (asset: Assets) => void;
  is_existing_asset_page?: boolean;
  select_type?: "checkbox" | "radio";
  show_download_checkbox?: boolean;
  productSlug?: string;
  isShowCheckbox?: boolean;
  isDisabledCheckbox?: boolean;
  isAllCheckBoxUnselected?: boolean;
  is_page_selected?: boolean;
  bulkSelectLimit?: number;
} & GridAssetsSelectProps) => {
  const { t } = useTranslation();
  const [is_default_selected_set, set_is_default_selected_set] =
    useState(false);
  const [selected_rows, set_selected_rows] = useState(selectedItems);

  const update_selected_items = (asset: Assets) => {
    const row_id = assets.findIndex((item) => item.id === asset.id);
    const updated_rows = selected_rows?.some(
      (row) => row.original.id === asset.id
    )
      ? selected_rows?.filter((row) => row.original.id !== asset.id) // remove the item from selected rows
      : [
          ...(!!selected_rows ? selected_rows : []), // add the item to selected rows
          { original: asset, id: `${row_id}` } as Row<Assets>,
        ];
    const selected_row = [{ original: asset, id: `${row_id}` } as Row<Assets>];
    set_selected_rows(select_type === "checkbox" ? updated_rows : selected_row);
    handleSelectedItems?.(
      select_type === "checkbox" ? updated_rows : selected_row
    );
  };

  useEffect(() => {
    if (selectedItems && !is_default_selected_set) {
      set_selected_rows(selectedItems);
      handleSelectedItems?.(selectedItems);
      return () => {
        set_is_default_selected_set(true);
      };
    }
  }, [handleSelectedItems, is_default_selected_set, selectedItems]);

  useEffect(() => {
    if (isAllCheckBoxUnselected) {
      set_selected_rows([]);
    }
  }, [isAllCheckBoxUnselected]);

  useEffect(() => {
    if (is_page_selected) {
      set_selected_rows(
        assets.map(
          (asset) =>
            ({ original: asset, id: `${asset.id}` } as unknown as Row<Assets>)
        )
      );
    }
  }, [assets, is_page_selected]);

  return (
    <GridContainer>
      {assets.length > 0 ? (
        assets.map((asset) => (
          <RenderOnViewportEntry key={asset.id}>
            <GridItem
              key={asset.id}
              asset={asset}
              assets={assets}
              handle_asset_visibility_change={handle_asset_visibility_change}
              on_download_asset={on_download_asset}
              on_edit_asset={on_edit_asset}
              on_remove_asset={on_remove_asset}
              can_select={!!selectedItems}
              is_selected={selected_rows?.some(
                (item) => item.original.id === asset.id
              )}
              on_select_item={() => update_selected_items(asset)}
              select_type={select_type}
              is_existing_asset_page={is_existing_asset_page}
              show_download_checkbox={show_download_checkbox}
              productSlug={productSlug}
              isShowCheckbox={isShowCheckbox}
              isDisabledCheckbox={isDisabledCheckbox}
              bulkSelectLimit={bulkSelectLimit || 0}
              selectedRowCount={selected_rows?.length || 0}
            />
          </RenderOnViewportEntry>
        ))
      ) : (
        <SmallText>{t("No items to show.")}</SmallText>
      )}
    </GridContainer>
  );
};
