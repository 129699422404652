import type { AxiosError } from "axios";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import React, { useContext, useState } from "react";
import { endpoints } from "../../../../../../endpoints";
import { isAxiosError, useStoreState } from "../../../../../../util/util";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import type { Assets } from "../../../../../../types/types.PIM";
import { EditDeleteModal } from "../../../../../../components/EditDeleteModal/EditDeleteModal";

export const DeleteAssetModal = ({
  show,
  onClose,
  onComplete,
  refreshAssetsList,
  assetData,
}: {
  onComplete: () => void;
  refreshAssetsList: any;
  show: boolean;
  onClose: () => void;
  assetData: Assets;
}) => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { notifySuccess, notifyError } = useContext(Notifications);

  const { tenant_id } = useStoreState();

  const { id, number_of_products } = assetData;

  const onDelete = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      await Axios.delete(endpoints.v2_tenants_id_pim_assets_id(tenant_id, id));
      await refreshAssetsList();
      notifySuccess(t("Asset successfully removed"));
      setIsSubmitting(false);
      onComplete();
    } catch (error) {
      if (
        isAxiosError(error) &&
        (error as AxiosError)?.response?.data?.message
      ) {
        notifyError(error?.response?.data?.message);
      } else {
        notifyError(t("could not delete an asset, Something went wrong."));
      }
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <EditDeleteModal
        title={t("Delete Asset")}
        isLoading={isSubmitting}
        show={show}
        onClose={onClose}
        onConfirm={onDelete}
        body={t(
          "This asset is currently used on {{number_of_products}} products. Are you sure you want to delete this Asset?",
          { number_of_products }
        )}
        confirmBtnLabel={t("Delete")}
      />
    </>
  );
};
